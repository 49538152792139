* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
}
